// Fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,200;0,400;0,500;0,700;0,900;1,200;1,400;1,500;1,700;1,900&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Colors
$defaultColor: #fcdebe;
$secondColor: #f9564f;
$mainFont: "Montserrat Alternates", sans-serif;

// ScrolBar Style

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: $secondColor;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #000;
}

// Mixin
@mixin PageTitle1 {
  font-size: 45px;
  font-weight: 900;
  margin: 0;
  font-family: $mainFont;
  color: #fff;
  letter-spacing: 2px;
}

@mixin PageTitle2 {
  font-size: 30px;
  color: $secondColor;
  font-family: $mainFont;
  letter-spacing: 0.7px;
}

@mixin laptopSpecs {
  ul {
    a {
      display: flex;
      text-decoration: none;
      cursor: pointer;
      svg {
        color: #fff;
        font-size: 30px;
        margin: 0 5px;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        font-family: $mainFont;
      }
      h2 {
        font-family: $mainFont;
        color: #fff;
        font-weight: 600;
      }
      &:hover svg {
        transform: scale(1.2) rotate(30deg) translateX(10px) translateY(-10px);
        font-weight: 900;
        color: $secondColor;
      }
    }
    li {
      color: rgb(231, 231, 231);
      font-family: $mainFont;
      margin: 20px 30px 10px;
      font-size: 20px;
      width: 100%;
    }
    p {
      color: $secondColor;
    }
  }
}

*,
*::after,
*::before {
  user-select: none;
}

body {
  background-color: #0b132b !important;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

nav.navbar {
  padding: 15px 10px;
  animation: animNav 1s ease;
  transition: all 1s linear;
  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-brand {
    font-size: 25px;
    color: #fff !important;
    font-family: $mainFont;
    text-decoration: none;

    b {
      font-family: $mainFont;
      color: $secondColor;
      font-weight: 900;
    }
  }
}

// About

.aboutInfo {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  padding: 40px 40px 0 40px;

  .side1 {
    width: 55%;
    // margin: 20px 0;
    .title {
      font-size: 70px;
      font-family: $mainFont;
      color: #fff;
      font-weight: 700;
      height: 255px;
    }
    .description {
      width: 75%;
      color: rgb(231, 231, 231);
      // margin: 15px 3px 60px;
      font-size: 28px;
      font-family: $mainFont;
    }
    .buttons {
      display: flex;
      .aboutBtn {
        background-color: $defaultColor;
        padding: 17px 40px;
        color: #000;
        font-size: 20px;
        text-decoration: none;
        font-family: $mainFont;
        font-weight: 500;
        display: flex;
        align-items: center;
        .buttonIcon {
          margin-left: 10px;
          animation: aboutBtnAnimation 5s linear 1s infinite;
        }
      }
      .portfolioBtn {
        padding: 17px 40px 17px 10px;
        font-size: 20px;
        text-decoration: none;
        font-family: $mainFont;
        font-weight: 500;
        margin-left: 10px;
        background-color: $secondColor;
        color: #000;
        display: flex;
        align-items: center;
        position: relative;
        .indicator {
          position: absolute;
          right: 15px;
          width: 9px;
          height: 9px;
          transform: rotate(-45deg);
          .arrowToRight {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: none;
            border-bottom: 2px solid $defaultColor;
            border-right: 2px solid $defaultColor;
            transition: all 1s linear;
            animation: animate 1s linear 1s infinite;
            &:nth-child(1) {
              top: -6px;
              left: -6px;
              animation-delay: 0s;
            }
            &:nth-child(2) {
              top: -3px;
              left: -3px;
              animation-delay: 0.2s;
            }
            &:nth-child(3) {
              top: 0;
              left: 0;
              animation-delay: 0.4s;
            }
          }
        }
      }
    }

    @keyframes animate {
      0% {
        border-color: $defaultColor;
        transform: translate(0, 0);
      }
      20% {
        border-color: $defaultColor;
        transform: translate(3px, 3px);
      }
      20.1%,
      100% {
        border-color: #000;
      }
    }
    @keyframes aboutBtnAnimation {
      20%,
      100% {
        transform: rotate(360deg);
      }
    }

    .socialNet {
      margin: 80px auto 0 auto;
      display: flex;
      padding: 0;
      justify-content: space-between;
      width: 300px;
      li {
        list-style: none;
        margin-right: 5px;
        a.links {
          text-decoration: none;
          display: block;
          width: 60px;
          height: 60px;
          background: #fff;
          text-align: left;
          transform: rotate(0deg) skew(0deg) translate(0, 0);
          transition: 0.5s;
          box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
          .iconSvg {
            font-size: 45px;
            color: #262626;
            line-height: 60px;
            transition: 0.5s;
            display: block;
            margin: 0 auto;
            height: 100%;
          }
          &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: -10px;
            height: 100%;
            width: 10px;
            background: #b1b1b1;
            transform: 0.5s;
            transform: rotate(0deg) skewY(-45deg);
          }
          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: -5px;
            height: 10px;
            width: 100%;
            background: #b1b1b1;
            transform: 0.5s;
            transform: rotate(0deg) skewX(-45deg);
          }
          &:hover {
            transform: rotate(0deg) skew(0deg) translateY(-20px);
            box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
          }
        }
        &:hover {
          a.links .iconSvg {
            color: #fff;
          }
          a.links span {
            color: #fff;
          }
        }
        &:nth-child(1):hover {
          a {
            background: rgb(255, 0, 0);
            &::before {
              background: rgb(160, 12, 12);
            }
            &::after {
              background: rgb(252, 69, 69);
            }
          }
        }
        &:nth-child(2):hover {
          a {
            background: #c13584;
            &::before {
              background: rgb(153, 26, 98);
            }
            &::after {
              background: rgb(248, 71, 171);
            }
          }
        }
        &:nth-child(3):hover {
          a {
            background: #00aced;
            &::before {
              background: #097aa5;
            }
            &::after {
              background: #53b9e0;
            }
          }
        }
        &:nth-child(4):hover {
          a {
            background: #333333;
            &::before {
              background: #534b4b;
            }
            &::after {
              background: #707070;
            }
          }
        }
      } //<li>
    }
  }

  .side2 {
    .homeImage {
      width: 83%;
      height: auto;
      border-radius: 50%;
    }
  }
}

.titles {
  text-align: center;
  margin-top: 75px;
  .title1 {
    @include PageTitle1();
  }

  .title2 {
    @include PageTitle2();
  }
}

// PORTFOLIO

.row {
  .plus {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    width: 50px;
    height: 50px;
    color: black;
    background: $secondColor;
    border-radius: 50%;
    border: 1px solid #0b132b;
    transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1),
      transform 200ms cubic-bezier(0.2, 0, 0.7, 1);
    opacity: 0;
    transition: opacity 1s linear;
    svg {
      font-size: 2em;
      line-height: 1.1em;
      display: block;
      margin: 0 auto;
    }
    &:hover {
      box-shadow: 0 0 1px 15px rgba(#8a3b58, 0.4),
        0 0 1px 30px rgba(#8a3b58, 0.1), 0 0 1px 45px rgba(#8a3b58, 0.1);
      svg {
        animation: upButtonAnimation 1.5s ease infinite;
      }
    }

    &.show {
      opacity: 1;
    }

    @keyframes upButtonAnimation {
      0% {
        transform: translateY(20px);
        opacity: 0;
      }

      100% {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
  .col-md-12 {
    padding-top: 50px;

    .projectCard {
      display: flex;
      // justify-content: space-between;
      margin: 50px 0;
      .projectImage {
        width: 50%;
        border-radius: 5px;
      }
      .aboutProject {
        margin: 30px 50px;
        .projectDescription {
          .projectName {
            color: #fff;
            font-weight: 600;
            font-family: $mainFont;
          }
          .projectParagraph {
            color: rgb(231, 231, 231);
            margin: 10px 0 30px;
            font-family: $mainFont;
          }
          .testLogin {
            margin: 0px;
            color: rgb(231, 231, 231);
            font-family: $mainFont;
          }
          .testPassword {
            margin-bottom: 20px;
            color: rgb(231, 231, 231);
            font-family: $mainFont;
          }
        }
        .projectButtons {
          height: 60px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .liveButton {
            background-color: $secondColor;
            padding: 17px 35px;
            color: #000;
            text-decoration: none;
            font-size: 20px;
            font-family: $mainFont;
            font-weight: 500;
            margin-right: 10px;
            svg {
              margin: 0 10px 0;
            }
          }

          .demoButton {
            background-color: $defaultColor;
            padding: 17px 25px;
            color: #000;
            text-decoration: none;
            font-size: 20px;
            font-family: $mainFont;
            font-weight: 500;
            svg {
              margin: 0 10px 0;
            }
          }
        }
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .pagination .page-item .page-link {
    background-color: $defaultColor;
    color: $secondColor;
    transition: all 0.3s linear;
    font-weight: 500;
    outline: none;
    border: none;
  }

  .pagination .page-item.active .page-link {
    background-color: #f9564f;
    color: #0b132b;
    z-index: 0;
  }

  .pagination .page-item .page-link:hover {
    background-color: #f9564f;
    color: #0b132b;
  }
}

// Setup

.setupMain {
  .setupImage {
    display: flex;
    justify-content: center;
    img {
      margin-top: 100px;
      width: 80%;
      border-radius: 10px;
    }
  }
  .container {
    .row {
      justify-content: center;
      .col-md-8 {
        .mySetup {
          padding: 50px 75px;
          .laptopSpecs {
            display: flex;
            justify-content: center;
            margin-bottom: 40px;
            @include laptopSpecs();
          }
        }
      }
    }
  }
}

// About
.aboutMeMain {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 85px;
  img.myImage {
    width: 50%;
    height: auto;
    border-radius: 20px;
  }
  .aboutMeText {
    padding: 0 0 30px 45px;
    font-size: 26px;
    width: 95%;
    color: #fff;
    font-family: $mainFont;
    .stack {
      padding: 30px 0 30px;
    }
  }
}

// Footer

.footer {
  padding: 20px;
  background-color: #0b132b;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  a {
    text-decoration: none;
    color: #fcdebe;
    &:hover {
      color: $defaultColor;
    }
  }

  span.heartIcon {
    animation: heart-beat 1s ease infinite;
    margin-left: 3px;
    position: absolute;
  }
}

@keyframes heart-beat {
  from {
    font-size: 18px;
  }
  to {
    font-size: 16px;
  }
}
