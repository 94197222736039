/* Sidebar menu */


  $whiteColor: #fcdebe94;
  $blueColor: #0b132bcb;


.sidebar-menu {
  width: fit-content;
  height: 100%;
  padding: 20px;
  background-color: #f9564f;
  display: flex;
  flex-direction: column;
  position: fixed;
  display: block;
  top: 0;
  right: 0%;
  transition: 0.5s ease;
  opacity: 0;
  z-index: 10;
  transform: translateX(100%);
  // display: none;
  .logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin-bottom: 50%;
    i svg {
      width: 35px;
      height: 25px;
    }
    #closeIcon:hover {
      cursor: pointer;
    }
  }
  &.active {
  transform: translateX(0);
    right: 0;
    opacity: 1;
  }
}





.navbar-brand {
  font-size: 25px;
  color: #fff !important;
  font-family: "Montserrat Alternates", sans-serif;
  b {
    font-family: "Montserrat Alternates", sans-serif;
    color: #fcdebe;
    font-weight: 900;
  }
}






.menu {
  color: rgba(255, 255, 255, 0.795);
  text-transform: uppercase;
  font-size: 40px;
  text-align: right;
  li {
    list-style: none;
    margin-bottom: 10px;
    position: relative;
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.63);
      font-weight: 800;
      transition: 0.4s ease;
      margin-right: 30px;
      &.active {
        color: #fff;
        -webkit-animation: activeLinkAnimation 200ms infinite;
        animation: activeLinkAnimation 800ms infinite;
      }
      &:hover {
        color: #ffffff;
      }
    }
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
}



/* RESPONSIVE */

@media screen and (min-width: 320px) and (max-width: 330px) {
  .sidebar-menu {
    width: 80%;
    padding: 10px;
    .navbar-brand {
      font-size: 17px;
    }
    .closeIcon {
      display: flex;
    }
    .menu {
      padding: 10px;
      li {
        font-size: 37px;
      }
    }
  }
}

@media screen and (min-width: 331px) and (max-width: 370px) {
  .sidebar-menu {
    width: 80%;
    padding: 20px;
    .navbar-brand {
      font-size: 19px;
    }
    .closeIcon {
      display: flex;
      svg {
        width: 45px;
        height: 25px;
      }
    }
    .menu {
      padding: 0px;
      li {
        font-size: 35px;
        a {
          margin-right: 35px;
        }
      }
    }
  }
}

@media screen and (min-width: 371px) and (max-width: 500px) {
  .sidebar-menu {
    width: 80%;
    padding: 20px;
    .logo {
      margin-bottom: 70%;
    }
    .navbar-brand {
      font-size: 19px;
    }
    .closeIcon {
      display: flex;
      svg {
        width: 45px;
        height: 25px;
      }
    }
    .menu {
      padding: 0px;
      li {
        font-size: 35px;
        a {
          margin-right: 35px;
        }
      }
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
  .sidebar-menu {
    padding: 20px;
    .logo {
      margin-bottom: 70%;
    }
    .navbar-brand {
      font-size: 19px;
    }
    .closeIcon {
      display: flex;
      svg {
        width: 45px;
        height: 25px;
      }
    }
    .menu {
      padding: 0px;
      li {
        font-size: 35px;
        a {
          margin-right: 35px;
        }
      }
    }
  }
}

/* Active Link Animation */

@keyframes activeLinkAnimation {
  0% {
    text-shadow: 5px 4px $whiteColor, -5px -6px $blueColor;
  }
  25% {
    text-shadow: -5px -6px $whiteColor, 5px 4px $blueColor;
  }
  50% {
    text-shadow: 5px -4px $whiteColor, -8px 4px $blueColor;
  }
  75% {
    text-shadow: -8px -4px $whiteColor, -5px -4px $blueColor;
  }
  100% {
    text-shadow: -5px 0 $whiteColor, 5px -4px $blueColor;
  }
}
