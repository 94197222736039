// Colors
$defaultColor: #fcdebe;
$secondColor: #f9564f;

// Home
@media screen and (min-width: 1200px) {
  .aboutInfo {
    .side1 {
      .socialNet {
        margin: 45px auto 0 10px;
      }
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  nav.navbar {
    .container-fluid {
      padding: 0;
    }
    padding: 12px;
    .navbar-brand {
      font-size: 25px;
      padding: 0;
    }

    .navbar-toggler {
      background-color: $secondColor;
      border-radius: 0;
      span {
        font-size: 15px;
      }
    }

    .navbar-nav {
      .nav-item {
        margin: 20px 20px;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .aboutInfo {
    display: flex;
    padding: 50px 0;
    align-items: flex-end;
    .side2 {
      width: 70%;
      text-align: center;
      .homeImage {
        width: 90%;
      }
    }
    .side1 {
      text-align: center;
      width: 100%;
      h1.title {
        font-size: 40px;
        height: 100px;
      }
      p.description {
        font-size: 25px;
        width: 100%;
        padding: 0 15px;
        margin: 20px 0 35px;
      }

      .buttons {
        justify-content: center;
        .aboutBtn,
        .portfolioBtn {
          padding: 15px 35px;
          font-size: 20px;
        }
      }

      .socialNet {
        margin: 45px auto;
        .links {
          padding: 0;
          margin: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  nav.navbar {
    .container-fluid {
      padding: 0;
    }
    padding: 12px;
    .navbar-brand {
      font-size: 23px;
      padding: 0;
    }

    .navbar-toggler {
      background-color: $secondColor;
      border-radius: 0;
      span {
        font-size: 15px;
      }
    }

    .navbar-nav {
      .nav-item {
        margin: 20px 20px;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .aboutInfo {
    display: flex;
    padding: 100px 0;
    align-items: center;
    .side2 {
      text-align: center;
      .homeImage {
        width: 90%;
      }
    }
    .side1 {
      text-align: center;
      width: 100%;
      h1.title {
        font-size: 35px;
        height: 85px;
      }
      p.description {
        font-size: 22px;
        width: 100%;
        padding: 0 15px;
        margin: 15px 0 25px;
      }

      .buttons {
        justify-content: center;
        .aboutBtn,
        .portfolioBtn {
          padding: 15px;
          font-size: 20px;
        }

        .portfolioBtn {
          padding: 15px 30px 15px 15px;
          .indicator {
            right: 10px;
            width: 9px;
            height: 9px;
          }
        }
      }

      .socialNet .links {
        padding: 0;
        margin: 10px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  nav.navbar {
    .container-fluid {
      padding: 0;
    }
    padding: 12px !important;
    .navbar-brand {
      font-size: 20px;
      padding: 0 10px;
    }

    .navbar-toggler {
      background-color: $secondColor;
      border-radius: 0;
      span {
        font-size: 15px;
      }
    }

    .navbar-nav {
      .nav-item {
        margin: 20px 20px;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .aboutInfo {
    display: flex;
    padding: 100px 0;
    align-items: center;
    .side2 {
      text-align: center;
      .homeImage {
        width: 90%;
      }
    }
    .side1 {
      text-align: center;
      width: 100%;
      h1.title {
        font-size: 35px;
        height: 130px;
      }
      p.description {
        font-size: 22px;
        width: 100%;
        padding: 0 15px;
        margin: 15px 0 25px;
      }

      .buttons {
        justify-content: center;
        .aboutBtn,
        .portfolioBtn {
          padding: 15px 20px;
          font-size: 20px;
        }

        .portfolioBtn {
          padding: 15px 30px 15px 15px;
          .indicator {
            right: 10px;
            width: 9px;
            height: 9px;
          }
        }
      }

      .socialNet .links {
        padding: 0;
        margin: 10px;
      }
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
  nav.navbar {
    .container-fluid {
      padding: 0;
    }
    padding: 0px;
    .navbar-brand {
      font-size: 28px;
      padding: 0;
    }

    .navbar-toggler {
      background-color: $secondColor;
      border-radius: 0;
      span {
        font-size: 15px;
      }
    }

    .navbar-nav {
      .nav-item {
        margin: 20px 0;
        font-size: 17px;
        text-align: center;
      }
    }
  }

  .aboutInfo {
    display: flex;
    padding: 30px 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .side2 {
      text-align: center;
      .homeImage {
        width: 330px;
      }
    }
    .side1 {
      text-align: center;
      width: 100%;
      padding: 10px;
      h1.title {
        font-size: 35px;
        height: 85px;
      }
      p.description {
        font-size: 25px;
        width: 100%;
        padding: 0 15px;
        margin: 15px 0 25px;
      }

      .buttons {
        justify-content: center;
        .aboutBtn,
        .portfolioBtn {
          padding: 15px;
          font-size: 20px;
        }

        .portfolioBtn {
          padding: 15px 30px 15px 15px;
          .indicator {
            right: 10px;
            width: 9px;
            height: 9px;
          }
        }
      }

      .socialNet .links {
        padding: 0;
        margin: 10px;
      }
    }
  }
  .footer {
    padding: 10px 0 15px 0;
  }
}

@media screen and (min-width: 371px) and (max-width: 500px) {
  nav.navbar {
    padding: 0;
    .container-fluid {
      padding: 0;
    }
    .navbar-brand {
      font-size: 24px;
      padding: 0;
    }

    .navbar-toggler {
      background-color: $secondColor;
      border-radius: 0;
      span {
        font-size: 15px;
      }
    }

    .navbar-nav {
      .nav-item {
        margin: 20px 0;
        font-size: 17px;
        text-align: center;
      }
    }
  }

  .aboutInfo {
    display: flex;
    padding: 30px 0;
    flex-direction: column;
    align-items: center;
    .side2 {
      text-align: center;
      .homeImage {
        width: 60%;
      }
    }
    .side1 {
      text-align: center;
      width: 100%;
      margin-top: 20px;
      h1.title {
        font-size: 27px;
        height: 65px;
      }
      p.description {
        font-size: 15px;
        width: 100%;
        padding: 0 15px;
        margin: 15px 0 25px;
      }

      .buttons {
        justify-content: center;
        .aboutBtn,
        .portfolioBtn {
          padding: 15px;
          font-size: 15px;
        }

        .portfolioBtn {
          padding: 15px 30px 15px 15px;
          .indicator {
            right: 10px;
            width: 8px;
            height: 8px;
          }
        }
      }

      .socialNet .links {
        padding: 0;
        margin: 10px;
      }
    }
  }
  .footer {
    padding: 10px 0 15px 0;
    a {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 331px) and (max-width: 370px) {
  nav.navbar {
    padding: 0;
    .container-fluid {
      padding: 0;
    }
    .navbar-brand {
      font-size: 20px;
      padding: 0 10px;
    }

    .navbar-toggler {
      background-color: $secondColor;
      border-radius: 0;
      span {
        font-size: 15px;
      }
    }

    .navbar-nav {
      .nav-item {
        margin: 20px 0;
        font-size: 17px;
        text-align: center;
      }
    }
  }

  .aboutInfo {
    display: flex;
    padding: 30px 0;
    flex-direction: column;
    align-items: center;
    .side2 {
      text-align: center;
      .homeImage {
        width: 60%;
      }
    }
    .side1 {
      text-align: center;
      width: 100%;
      h1.title {
        font-size: 27px !important;
        height: 65px;
      }
      p {
        font-size: 15px !important;
        width: 100% !important;
        padding: 0 15px !important;
        margin: 15px 0 25px !important;
      }

      .buttons {
        justify-content: center;
        .aboutBtn,
        .portfolioBtn {
          padding: 15px;
          font-size: 15px;
        }

        .portfolioBtn {
          padding: 15px 30px 15px 15px;
          .indicator {
            right: 10px;
            width: 8px;
            height: 8px;
          }
        }
      }

      .socialNet {
        width: 80%;
        margin: 50px auto 0 auto;
        .links {
          padding: 0;
          margin-right: 5px;
        }
      }
    }
  }
  .footer {
    padding: 10px 0 15px 0;
    a {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 330px) {
  nav.navbar {
    padding: 0;
    .container-fluid {
      padding: 0;
    }
    a.navbar-brand {
      font-size: 20px;
      padding: 0;
    }

    .navbar-toggler {
      background-color: $secondColor;
      border-radius: 0;
      span {
        font-size: 15px;
      }
    }

    .navbar-nav {
      .nav-item {
        margin: 20px 0;
        font-size: 17px;
        text-align: center;
      }
    }
  }

  .aboutInfo {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    align-items: center;
    .side2 {
      text-align: center;
      width: 100%;
      .homeImage {
        width: 100%;
      }
    }
    .side1 {
      text-align: center;
      width: 100%;
      margin: 20px auto;
      .title {
        font-size: 27px !important;
        height: 100px;
      }
      p {
        font-size: 15px !important;
        width: 100% !important;
        padding: 0 15px !important;
        margin: 15px 0 25px !important;
      }

      .buttons {
        justify-content: center;
        .aboutBtn,
        .portfolioBtn {
          padding: 15px;
          font-size: 15px;
          height: 55px;
        }

        .portfolioBtn {
          padding: 15px 35px 15px 10px;
          .indicator {
            right: 10px;
            width: 8px;
            height: 8px;
          }
        }
      }

      .socialNet {
        width: 90%;
        margin: 50px auto 0 auto;
        .links {
          padding: 0;
          margin-right: 5px;
        }
      }
    }
  }
  .footer {
    padding: 10px 0 15px 0;
    a {
      font-size: 12px;
    }
  }
}

// Portfolio

@media screen and (min-width: 320px) and (max-width: 330px) {
  .titles {
    margin-top: 20px;
    .title1 {
      font-size: 30px;
    }
    .title2 {
      font-size: 20px;
    }
  }

  .row .col-md-12 {
    padding-top: 10px;
    .projectCard {
      display: flex;
      flex-direction: column;
      margin: 0;
      align-items: center;
      .projectImage {
        width: 90%;
        border-radius: 5px;
      }
      .aboutProject {
        text-align: center;
        margin: 15px 20px;
        .projectDescription {
          .projectParagraph {
            margin: 10px 0 15px;
          }
        }
        .projectButtons {
          justify-content: center;
          .liveButton {
            display: block;
            padding: 10px 20px;
          }
          .demoButton {
            display: block;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 331px) and (max-width: 500px) {
  .titles {
    margin-top: 20px;
    .title1 {
      font-size: 30px;
    }
    .title2 {
      font-size: 20px;
    }
  }

  .row .col-md-12 {
    padding-top: 10px;
    .projectCard {
      display: flex;
      flex-direction: column;
      margin: 0;
      align-items: center;
      .projectImage {
        width: 90%;
        border-radius: 5px;
      }
      .aboutProject {
        text-align: center;
        margin: 15px 20px;
        .projectDescription {
          .projectParagraph {
            margin: 10px 0 15px;
          }
        }
        .projectButtons {
          justify-content: center;
          .liveButton {
            display: block;
            padding: 10px 20px;
          }
          .demoButton {
            display: block;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .titles {
    margin-top: 20px;
    .title1 {
      font-size: 45px;
    }
    .title2 {
      font-size: 30px;
    }
  }

  .row .col-md-12 {
    padding-top: 10px;
    .projectCard {
      display: flex;
      flex-direction: column;
      margin: 0;
      align-items: center;
      .projectImage {
        width: 90%;
        border-radius: 5px;
      }
      .aboutProject {
        text-align: center;
        margin: 15px 20px;
        .projectDescription {
          .projectParagraph {
            margin: 10px 0 15px;
          }
        }
        .projectButtons {
          justify-content: center;
          .liveButton {
            padding: 10px 40px;
          }
          .demoButton {
            display: block;
            padding: 10px 40px;
          }
        }
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .titles {
    margin-top: 20px;
    .title1 {
      font-size: 45px;
    }
    .title2 {
      font-size: 30px;
    }
  }

  .row .col-md-12 {
    padding-top: 10px;
    .projectCard {
      display: flex;
      margin: 20px 0;
      align-items: center;
      justify-content: center;
      .projectImage {
        width: 60%;
        border-radius: 5px;
      }
      .aboutProject {
        width: 40%;
        text-align: center;
        margin: 15px 20px;
        .projectDescription {
          .projectParagraph {
            margin: 10px 0 15px;
          }
        }
        .projectButtons {
          justify-content: center;
          .liveButton {
            padding: 10px 10px;
            width: 45%;
          }
          .demoButton {
            display: block;
            padding: 10px 8px;
            width: 55%;
          }
        }
      }
    }
  }
}

// About Me

@media screen and (max-width: 330px) {
  .aboutMeMain {
    display: flex;
    padding: 25px;
    flex-direction: column;

    img.myImage {
      width: 80%;
      height: auto;
      border-radius: 20px;
      margin: 10px auto 30px auto;
    }

    .aboutMeText {
      padding: 0;
      width: 100%;
      text-align: center;
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 331px) and (max-width: 568px) {
  .aboutMeMain {
    display: flex;
    padding: 25px;
    flex-direction: column;

    img.myImage {
      width: 80%;
      height: auto;
      border-radius: 20px;
      margin: 10px auto 30px auto;
    }

    .aboutMeText {
      padding: 0;
      width: 100%;
      text-align: center;
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 569px) and (max-width: 768px) {
  .aboutMeMain {
    display: flex;
    padding: 30px 30px 0 30px;
    flex-direction: column;

    img.myImage {
      width: 90%;
      height: auto;
      border-radius: 20px;
      margin: 10px auto 30px auto;
    }

    .aboutMeText {
      padding: 0;
      width: 100%;
      text-align: center;
      font-size: 30px;
    }
  }
}
